import React, { useState, useEffect, useRef, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";

import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PersonIcon from '@material-ui/icons/Person'; 
import VisibilityIcon from '@material-ui/icons/Visibility';
import {Can} from "../Can"

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import MarkdownWrapper from "../MarkdownWrapper";
import { Tooltip } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import Stopwatch from "../Stopwatch";

import ch_waba from "../../assets/channel_waba.svg"
import ch_whatsapp from "../../assets/channel_whatsapp.svg"
import ch_instagram from "../../assets/channel_instagram.svg"
import ch_messenger from "../../assets/channel_messenger.svg"
import ch_facebook from "../../assets/channel_facebook.svg"
import ch_socket from "../../assets/channel_socket.svg"
import ch_webchat from "../../assets/channel_webchat.svg"

const useStyles = makeStyles(theme => ({
	'& .MuiBadge-badge': {
		backgroundColor: '#44b700',
		color: '#44b700',
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		'&::after': {
		  position: 'absolute',
		  top: 0,
		  left: 0,
		  width: '100%',
		  height: '100%',
		  borderRadius: '50%',
		  animation: 'ripple 1.2s infinite ease-in-out',
		  border: '1px solid currentColor',
		  content: '""',
		},
	  },
	ticket: {
		position: "relative",
	},

	pendingTicket: {
		cursor: "unset",
	},

	noTicketsDiv: {
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},

	noTicketsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},

	noTicketsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},

	contactNameWrapper: {
		display: "flex",
		justifyContent: "space-between",
	},

	lastMessageTime: {
		justifySelf: "flex-end",
	},

	closedBadge: {
		alignSelf: "center",
		justifySelf: "flex-end",
		marginRight: 32,
		marginLeft: "auto",
	},

	contactLastMessage: {
		paddingRight: 20,
	},

	newMessagesCount: {
		alignSelf: "center",
		marginRight: 8,
		marginLeft: "auto",
	},

	badgeStyle: {
		color: "white",
		backgroundColor: green[500],
	},

	acceptButton: {
		position: "absolute",
		left: "50%",
	},

	
	spyButton: {
		position: "absolute",
		left: "65%",
	},

	ticketQueueColor: {
		flex: "none",
		width: "11px",
		height: "110%",
		position: "absolute",
		top: "0%",
		left: "0%",
	},
	timeTag: {
		/*right: "-20px",
		bottom: "2px",*/
		marginLeft:90,
		background: "#beaed2",
		color: "#000000",
		border:"1px solid #CCC",
		padding: 2,
		paddingLeft: 5,
		paddingRight: 5,
		borderRadius: 3,
		fontSize: "0.9em"
	},
	userTag: {
		position: "absolute",
		marginRight: 35,
		right: -34,
		bottom: 36,
		background: "#4bc0c0",
		color: "#000000",
		border:"1px solid #CCC",
		padding: 1,
		paddingLeft: 5,
		paddingRight: 5,
		borderRadius: 3,
		fontSize: "0.9em"
	},
	connTag: {
		position: "absolute",
		marginRight: 35,
		right: -34,
		bottom: 9,
		background: "#ffffff",
		color: "#000000",
		border:"1px solid #000000",
		padding: 1,
		paddingLeft: 5,
		paddingRight: 5,
		borderRadius: 5,
		fontSize: "0.9em"
	},
	avatar:{
        width: "60px",
        height: "60px",
        borderRadius:"10%",
		marginRight:"10px"
    },
	avatar2:{
        width: "40px",
        height: "40px",
        fontWeight:900,
		marginRight:10
    },
	customTableCell: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
}));


const TicketListItem = ({ ticket }) => {
	const classes = useStyles();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const { ticketId } = useParams();
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);

	console.log("currTicket", ticket)


	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const handleAcepptTicket = async id => {
		setLoading(true);
		try {
			
			await api.put(`/tickets/${id}`, {
				status: "open",
				userId: user?.id,
			});
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		if (isMounted.current) {
			setLoading(false);
		}
		history.push(`/tickets/${id}`);
	};

	const handleSpyTicket = async id => {
		setLoading(true);
		history.push(`/tickets/${id}`);
		setLoading(false);
	};

	const stringToColor = (string) => {
		if (string != undefined && string != '' ){
			let hash = 0;
			let i;
		  
			/* eslint-disable no-bitwise */
			for (i = 0; i < string.length; i += 1) {
			  hash = string.charCodeAt(i) + ((hash << 5) - hash);
			}
		  
			let color = '#';
		  
			for (i = 0; i < 3; i += 1) {
			  const value = (hash >> (i * 8)) & 0xff;
			  color += `00${value.toString(16)}`.slice(-2);
			}
			/* eslint-enable no-bitwise */
		  
			return color;
		}else{
			return "#ff3232";
		}

	}

	const stringAvatar = (name) => {
		let currInitials;

		if (name != undefined && name != '' ){
			if (/^\d+$/.test(name) || name == "") {
				currInitials = name.slice(-2);
			} else {
	
				let namesplit=name.split(' ');
	
				if (namesplit.length==1){
					currInitials = `${name.split(' ')[0][0]}${name.split(' ')[0][1]}`;
				}else if(namesplit.length>1){
					currInitials = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
				}
			}
	
			return {
				sx: {
					bgcolor: stringToColor(name),
				},
				children: currInitials,
			};
		}else{
			return {
				sx: {
					bgcolor: "#ff3232",
				},
				children: "⚠️",
			};
		}
		

	}

	function getContrastColor(hexColor){
		let r = parseInt(hexColor.substr(1, 2), 16); // Get red value
		let g = parseInt(hexColor.substr(3, 2), 16); // Get green value
		let b = parseInt(hexColor.substr(5, 2), 16); // Get blue value

		const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

		return luminance > 0.5 ? '#000000' : '#FFFFFF'; // Return black for bright colors, white for dark colors
	}
	function getContrastText(hexColor) {
		// Convert hex to RGB
		let rgbColor = parseInt(hexColor.substring(1), 16); 
		let r = (rgbColor >> 16) & 255;
		let g = (rgbColor >> 8) & 255;
		let b = rgbColor & 255;

		// Calculate contrast
		let contrastR = 255 - r;
		let contrastG = 255 - g;
		let contrastB = 255 - b;

		// Convert RGB to hex
		const contrastColor = ((contrastR << 16) | (contrastG << 8) | contrastB).toString(16);
			
		return "#" + ("000000" + contrastColor).slice(-6);
	}

	const handleSelectTicket = id => {
		history.push(`/tickets/${id}`);
	};

	const renderChannelIcon = channel => {
		return (
				<>
					{channel == "whatsapp" && (<img src={ch_whatsapp} style={{width:15}}/>)}
					{channel == "waba" && (<img src={ch_waba} style={{width:15}} /> )}
					{channel.split("::")[0] == "socket" && (<img src={ch_webchat} style={{width:15}} /> )}
				</>
			
		);
	};



	return (
		<React.Fragment key={ticket.id}>
			<ListItem
				dense
				button
				onClick={e => {
					if (ticket.status === "pending") return;
					handleSelectTicket(ticket.id);
				}}
				selected={ticketId && +ticketId === ticket.id}
				className={clsx(classes.ticket, {
					[classes.pendingTicket]: ticket.status === "pending",
				})}
			>
				<Tooltip
					arrow
					placement="right"
					title={ticket.queue?.name || "Sem fila"}
				>
					<span
						style={{ backgroundColor: ticket.queue?.color || "#7C7C7C"}}
						className={classes.ticketQueueColor}
					></span>
				</Tooltip>
				
				<ListItemAvatar>
				<Badge
					overlap="circular"
					anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
					}}
					badgeContent={<span className={classes.timeTag}>
						
							{isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
									<>{format(parseISO(ticket.updatedAt), "HH:mm")} <Stopwatch type="normal" ticketStatus={ticket.status} createdAt={ticket.createdAt} updatedAt={ticket.updatedAt} ticketQueue={ticket.queue}/></>
									) : (
									<>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")} <Stopwatch type="normal" ticketStatus={ticket.status} createdAt={ticket.createdAt} updatedAt={ticket.updatedAt} ticketQueue={ticket.queue}/></>
									)}
								</span>
								}
				>
						{ticket?.contact?.displayPic == "ShowPic" && (<Avatar src={ticket?.contact?.profilePicUrl} className={classes.avatar} />)}
							
						{ticket?.contact?.displayPic != "ShowPic" && (<Avatar {...stringAvatar(ticket?.contact?.name)} className={classes.avatar2} style={{backgroundColor:stringToColor(ticket?.contact?.name), color:getContrastColor(stringToColor(ticket?.contact?.name))}}/>)}
						
				
						
				
				
				</Badge>
				</ListItemAvatar>
				<ListItemText
					disableTypography
					primary={
						<span className={classes.contactNameWrapper}>
							<Typography
								noWrap
								component="span"
								variant="body2"
								color="textPrimary"
							>
								{ticket?.contact?.name}
							</Typography>
							{ticket.status === "closed" && (
								<Badge
									className={classes.closedBadge}
									badgeContent={"Resolvido"}
									color="primary"
								/>
							)}


							{ticket.channel.split("::")[0] == "socket" && (<div className={classes.connTag} style={{marginRight:"40px"}} title={i18n.t("ticketsList.connectionTitle")}>{renderChannelIcon(ticket.channel)} WebSocket</div>)}
							{ticket.channel.split("::")[0] != "socket" && (<div className={classes.connTag} style={{marginRight:"40px"}} title={i18n.t("ticketsList.connectionTitle")}>{renderChannelIcon(ticket.channel)} {ticket?.whatsapp?.name}</div>)}
	

			

							
							{ticket.user?.name && (
								<div className={classes.userTag} style={{marginRight:"40px"}} title={i18n.t("messagesList.header.assignedTo")}><PersonIcon/> {ticket.user?.name}</div>
							)}
						</span>
					}
					secondary={
						<span className={classes.contactNameWrapper}>
							<Typography
								className={classes.contactLastMessage}
								noWrap
								component="span"
								variant="body2"
								color="textSecondary"
							>
								{ticket.lastMessage ? (
									<MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
								) : (
									<br />
								)}
							</Typography>
							

							<Badge
								className={classes.newMessagesCount}
								badgeContent={ticket.unreadMessages}
								classes={{
									badge: classes.badgeStyle,
								}}
							/>
						</span>
					}
				/>
				{ticket.status === "pending" && (
					<>
					<ButtonWithSpinner
						color="primary"
						variant="contained"
						className={classes.acceptButton}
						size="small"
						loading={loading}
						onClick={e => handleAcepptTicket(ticket.id)}
					>
						{i18n.t("ticketsList.buttons.accept")}
					</ButtonWithSpinner>
					<Can
                                  role={user.profile}
                                  perform="drawer-items:super"
                                  yes={() => (
                                    <>
										<ButtonWithSpinner
											color="primary"
											variant="contained"
											className={classes.spyButton}
											size="small"
											loading={loading}
											onClick={e => handleSpyTicket(ticket.id)}
										>
											{i18n.t("ticketsList.buttons.spy")}
										</ButtonWithSpinner>
                                    </>
                                  )}
                                /> 
	
					</>
					
					
				)}
			</ListItem>
			<Divider variant="inset" component="li" />
		</React.Fragment>
	);
};

export default TicketListItem;
